@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.AuthLayout {
  height: 100vh;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.4);

  &Header {
    @extend .GlobalContainer;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    @include msd {
      padding: 0 20px;
    }
  }

  &Body {
    @extend .GlobalContainer;
    padding: 24px 0;
    padding-top: 45px;
    padding-bottom: 100px;
    height: calc(100vh - 64px);

    @include msd {
      padding: 45px 20px;
    }
  }

  &Title {
    text-align: center;
  }
}

.AuthWrapper {
  width: 740px;
  min-height: 560px;
  background-color: $white-3;
  box-shadow: 0px 4px 6px rgba(150, 149, 149, 0.16);
  margin: 0 auto;
  position: relative;
  display: flex;

  &Image {
    width: calc(740px - 480px);
    padding: 0 25px;
    background-color: #136fd3;
    position: relative;
    &Title {
      margin-top: 68px;
      text-align: center;
      &LineBg {
        background-repeat: no-repeat;
        background-position-x: 21px;
        background-position-y: 25px;
      }

      &_context {
        font-size: 32px !important;
      }
    }

    &_JobDetailTitle {
      margin-top: 40px;
    }

    &Context {
      width: 100%;
      margin-top: 20px;
      position: absolute;
      left: 0;
      bottom: 0;
      img {
        width: 100%;
        display: block;
      }
    }

    &Brush {
      position: absolute;
      top: -113px;
      width: 77px !important;
      left: 27px;
    }

    @include msd {
      display: none;
    }
  }

  &Info {
    width: 480px;
    padding: 50px 25px;
    position: relative;
  }

  &Loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-align: center;

    .loadingLogo,
    .loadingIndicator {
      width: 180px;
    }

    .loadingLogo {
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }

    .loadingIndicator {
      margin-bottom: 48px;
    }
  }

  @include msd {
    width: 100%;
    padding: 20px 30px 0px;
  }

  @include sm {
    padding: 0;
  }
}

.AuthJobDetailWrapper {
  min-height: 530px !important;
}

.AuthHeading {
  text-align: center;
}

.AuthCTA {
  text-align: center;
  margin-top: 20px;

  p {
    margin: 0;
    line-height: 20px;
  }
}

.RegisterForm {
  margin-top: 20px;

  &Input {
    width: 100%;
    margin-top: 16px !important;
  }

  &Name {
    display: flex;
    justify-content: space-between;

    & > * {
      width: 49%;
    }
  }

  @include sm {
    &Name {
      display: block;
      & > * {
        width: 100%;
      }
    }
  }
}

.RegisterEmailNewsletter {
  margin: 10px 0 10px;
}

.RegisterButton {
  width: 100%;
  margin-top: 25px !important;
}

.RegisterAgreement {
  display: block;
  text-align: center;
  margin-top: 15px;
  color: #707070;
}

.fieldError {
  margin: 0 0 10px !important;
}

.RegisterDivider {
  position: relative;
  height: 16px;
  margin-top: 28px;

  &Text {
    color: $lightgrey;
    position: absolute;
    background-color: $white-3;
    width: 100px;
    z-index: 1;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: $lightgrey-2;
    top: 50%;
    left: 0;
  }
}

.customizeSendOTPContainerMainField {
  width: 260px;
}
